import React from "react";
import scss from "./page-header-utilities.scss";

export interface PageHeaderUtilitiesProps {
	/** Global utilities. */
	general?: React.ReactNode;
	/** Product specific utilities. */
	productSpecific?: React.ReactNode;
	/** Learn info box options. */
	learnInfoBoxOptions?: PageHeaderLearnInfoBoxProps;
}

export type PageHeaderLearnInfoBoxProps = {
	button: React.ReactNode;
	content: React.ReactNode;
	expanded: boolean;
};

export function PageHeaderUtilities(props: PageHeaderUtilitiesProps): JSX.Element {
	const { general, learnInfoBoxOptions, productSpecific } = props;

	return (
		<>
			{(general || learnInfoBoxOptions || productSpecific) && (
				<div className={scss.utilities}>
					{general}
					{learnInfoBoxOptions?.button}
					{productSpecific}
				</div>
			)}
		</>
	);
}
