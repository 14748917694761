import React, { useRef, useState } from "react";
import {
	Button,
	H3,
	Icon,
	IconDownload,
	IconFunnel,
	IconGoalTarget,
	IconGroup,
	IconOptions,
	IconSearch,
	IconSettings,
	InlineText,
	Link,
	Ol,
	Paragraph,
	TextContainer,
	Ul,
	useFormattingLanguage,
} from "@siteimprove/fancylib";
import { IllustrationEducate } from "@siteimprove/fancyvisuals";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { PageHeader } from "../page-header/page-header";
import {
	colPages,
	colVisits,
	Site,
	SitePicker,
} from "../../actions-and-controls/site-picker/site-picker";
import { PageHeaderPicker } from "../page-header-picker/page-header-picker";
import {
	FormattedNumber,
	toFormattedNumberString,
} from "../../text/formatted-number/formatted-number";
import { Starred } from "../../actions-and-controls/starred/starred";
import { SortField } from "../../tables-and-lists/column-header/column-header";
import { invertDirection, Table } from "../../tables-and-lists/table/table";
import { useData } from "../../../utils/data-fetch";
import {
	PeriodPicker,
	PeriodPickerValue,
} from "../../actions-and-controls/period-picker/period-picker";
import { Message } from "../../feedback/message/message";
import { EmptyState } from "../../visuals/empty-state/empty-state";
import { useDesignToken } from "../../context/theme/theme";
import { LearnContent, useLearnInfoBox } from "../../feedback/learn-info-box/learn-info-box";
import { BaseLayoutNew } from "./base-layout-new";

export const Meta: DocPageMeta = {
	category: "Structure",
	title: "Base Layout (New)",
	notepad: "https://hackmd.io/YI-m7lQmRyysvkRI4pQqYQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Base Layout (New)"
			subTitle="The Base Layout component establishes a standardized structure at the top of the UI, aiding user navigation, providing context, and offering quick access to essential actions."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={BaseLayoutNew} />
				<Header.H2>Composition</Header.H2>
				<Paragraph>The Base Layout is ideal for composing the following elements:</Paragraph>
				<br />
				<Ol
					items={[
						<>
							<Header.H3>Horizontal Navigation</Header.H3>
							<Paragraph>
								Reserved for global actions and branding elements, maintaining consistent placement
								across the platform.
							</Paragraph>
							<br />
						</>,
						<>
							<Header.H3>Side navigation</Header.H3>
							<Paragraph>
								The primary means for navigating the platform's core features and sections.
							</Paragraph>
							<br />
						</>,
						<>
							<Header.H3>Header</Header.H3>
							<Paragraph>Contains the following elements:</Paragraph>
							<Ul
								items={[
									<>
										<b>Page Title</b>: Clearly identifies the current page or section, using the
										same label as the corresponding side navigation item for consistency. Keep
										titles concise and informative.
									</>,
									<>
										<b>Breadcrumbs</b> (Optional): Display the user's navigation path within the
										platform, enhancing context and discoverability. Recommended for most pages
										unless the navigation is shallow.
									</>,
									<>
										<b>Utilities</b> (Optional): Contextual actions or tools specific to the current
										page or section. Positioned based on their scope, with global utilities on the
										left and page-specific utilities on the right.
									</>,
									<>
										<b>Filters</b> (Required): Enable users to refine and explore data on the page,
										improving discoverability. Prioritize the most frequently used filters and
										ensure they adapt to different screen sizes.
									</>,
									<>
										<b>Dashboard Picker</b> (Optional): Allows users to personalize their view by
										selecting a preferred dashboard upon entering the platform.
									</>,
									<>
										<b>Alerts</b> (Optional, Use Sparingly): Display critical system-wide messages
										that persist above the content area. Use sparingly to avoid overwhelming users.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Header base</Header.H3>
				<Paragraph>
					Features a persistent page title, serving as a constant reference point for users
					throughout their navigation. Ensure consistency by using the same label from the side
					navigation as the page title.
				</Paragraph>
				<Example fn={UsageHeaderBase} />
				<Header.H3>Header with navigation</Header.H3>
				<Paragraph>
					Includes breadcrumbs to provide users with a clear understanding of their navigation path
					and context. This is especially useful in more complex modules or sections of the
					platform.
				</Paragraph>
				<Example fn={UsageHeaderWithNavigation} />
				<Header.H3>Header with utilities</Header.H3>
				<Paragraph>
					Presents utilities and actions for user interaction, positioned based on their scope:
					product-specific utilities on the left and global utilities on the right. Prioritize the
					most important actions for the current context.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Global utilities</b>: Located on the far left, these are universal functions, such
							as export, search, settings, and similar features.
						</>,
						<>
							<b>Learn info box</b>: Dedicated to the learn info box button and the learn info box
							content is displayed below the utilities row.
						</>,
						<>
							<b>Product-specific utilities</b>: Located on the far right, these are functionalities
							relevant to the current page or product users are interacting with.
						</>,
					]}
				/>
				<Example fn={UsageHeaderWithUtilities} />
				<Header.H3>Header with filters</Header.H3>
				<Paragraph>
					Features a filter bar to enable users to refine and explore data displayed on the page.
					This improves data discoverability and empowers users to focus on the most relevant
					information. Arrange filters by frequency of use, with the most common filters appearing
					first. Examples include:
				</Paragraph>
				<Ul
					items={[
						"Site picker: allow users to select a site",
						"Group picker: allow users to filter based on pre-defined groups of pages.",
						"Period picker: enable filtering by specific timeframes (e.g., date range, month, quarter).",
					]}
				/>
				<Example fn={UsageHeaderWithFilters} />
				<Header.H3>Header with alert</Header.H3>
				<Paragraph>
					Displays critical, system-wide messages that persist over the content area. Use header
					alerts sparingly and only for truly urgent messages to avoid information overload.
				</Paragraph>
				<Example fn={UsageHeaderWithAlert} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BaseLayoutNew}
					initialProps={{
						children:
							"Social Media that have referred visitors to your website. Expand the table for referring social media pages, trend graphs, and entry pages.",
						pageHeader: <PageHeader title="Page title" />,
						id: "content",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Do's</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Focus on the most important actions for the current context.",
							"Strictly adhere to Siteimprove's brand guidelines for colors, typography, and iconography.",
							"Use ample white space to maintain a clean and uncluttered appearance.",
							"Ensure a balanced distribution of elements throughout the header.",
							"Reveal additional options or actions as needed, rather than displaying everything at once.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Don'ts</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Avoid overloading the header with too many features, which can distract users from core tasks.",
							"Reserve alerts for truly critical, time-sensitive messages requiring immediate user attention.",
							"Prioritize essential actions and consider moving less-used ones to dropdown menus or other locations.",
							"Avoid hiding crucial actions behind unclear icons or gestures.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<InlineText>
						For developers, ensure the header is accessible by adhering to the following best
						practices:
					</InlineText>
					<Ul
						items={[
							`Breadcrumbs: Placed inside the <main> element. Make the breadcrumb container a <nav aria-label="Breadcrumbs">...</a>`,
							`Heading: Placed inside the <main> element. Make the heading an <h1> element.`,
							`Main content section: Use the <main> element for this (Note: A page must only have one <main> element). It must have id="content" and tabindex="-1" for the page's="Skip to main content" link to work.`,
							`Page toolbar: Placed inside the <main> element, This container should have role="group" and aria-label="Page toolbar"`,
							`Page filter: Placed inside the <main> element. This container should have role="group" and aria-label="Page filter"`,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph emphasis="strong">Page Titles:</Paragraph>
					<Ul
						items={[
							"Use the same label from the side navigation for consistency.",
							<>Keep them short, informative, and in sentence case (e.g., "Account Settings").</>,
						]}
					/>
					<Paragraph emphasis="strong">Button Labels:</Paragraph>
					<Ul
						items={[
							<>Employ action verbs (e.g., "Create," "Edit," "Delete").</>,
							<>
								Consult the <a href="https://fancy.siteimprove.com/Writing/word-list">Word list</a>{" "}
								for approved terminology.
							</>,
						]}
					/>
					<Paragraph emphasis="strong">Alert Messages:</Paragraph>
					<Ul
						items={[
							"Prioritize clarity and conciseness.",
							"Focus on the essential information users need to know.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const UsageHeaderBase = () => (
	<BaseLayoutNew id="content" pageHeader={<PageHeader title="Page title" />}>
		<>Page content 🦔</>
	</BaseLayoutNew>
);

const UsageHeaderWithNavigation = () => (
	<BaseLayoutNew
		id="content"
		pageHeader={
			<PageHeader
				title="Page title"
				breadcrumbs={{
					"aria-label": "Breadcrumbs",
					items: [
						{ title: "Level 1", url: "https://fancylib.netlify.app" },
						{ title: "Level 2", url: null },
					],
				}}
			/>
		}
	>
		<>Page content 🦔</>
	</BaseLayoutNew>
);

const UsageHeaderWithUtilities = () => {
	const [learnInfoBoxExpanded, setLearnInfoBoxExpanded] = useState(false);

	const [learnInfoBoxButton, learnInfoBoxContent] = useLearnInfoBox({
		heading: "Learn info box example",
		content: (
			<LearnContent
				columns={[
					<TextContainer key={1}>
						<H3>Lorem ipsum</H3>
						<Paragraph>
							Nullam consequat ligula sit amet diam congue porttitor. Etiam finibus dignissim
							accumsan.
						</Paragraph>
					</TextContainer>,
					<TextContainer key={2}>
						<H3>Etiam finibus</H3>
						<Paragraph>
							In hac habitasse platea dictumst. Sed ut perspiciatis unde omnis iste natus error sit
							voluptatem accusantium doloremque laudantium. Et harum quidem rerum facilis est et
							expedita distinctio.
						</Paragraph>
					</TextContainer>,
				]}
			/>
		),
		buttonSize: "medium",
		onToggle: setLearnInfoBoxExpanded,
	});

	return (
		<BaseLayoutNew
			id="content"
			pageHeader={
				<PageHeader
					title="Page title"
					utilities={{
						general: (
							<>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSearch />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconDownload />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSettings />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconOptions />
									</Icon>
								</Button>
							</>
						),
						productSpecific: (
							<>
								<Button variant="primary">Primary action</Button>
								<Button variant="secondary">Secondary action</Button>
							</>
						),
						learnInfoBoxOptions: {
							content: learnInfoBoxContent,
							button: learnInfoBoxButton,
							expanded: learnInfoBoxExpanded,
						},
					}}
				/>
			}
		>
			<>Page content 🦔</>
		</BaseLayoutNew>
	);
};

/** @visualDiffDelay 5000 */
const UsageHeaderWithFilters = () => {
	const locale = useFormattingLanguage();

	type SitesRequest = {
		query: string;
		pageNumber: number;
		pageSize: number;
		sortField: SortField<Site>;
	};

	const [sitesRequest, setSitesRequest] = useState<SitesRequest>({
		query: "",
		pageNumber: 1,
		pageSize: 20,
		sortField: { property: "isFavorite", direction: "asc" },
	});

	const { api: sitesApi } = useSitesDataAPI();

	const sitesFetchDataFn = async (request: SitesRequest, signal?: AbortSignal) => {
		// Simulate a delay to display the loading state.
		await new Promise((resolve) => setTimeout(resolve, 2000));
		return await sitesApi.getSites(request, signal);
	};

	const {
		data: sitesData,
		loading: loadingSites,
		triggerRender: sitesTriggerRender,
	} = useData(sitesFetchDataFn, sitesRequest);
	const [selectedSite, setSelectedSite] = useState<Site>();

	const loading = loadingSites && sitesData === null;

	// Period Picker
	const [periodPickerValue, setPeriodPickerValue] = useState<PeriodPickerValue | null>(null);

	// Activity Plans
	type ActivityPlan = {
		isFavorite: boolean;
		name: string;
		searchEngineVisibility: number;
		keywords: number;
		competitors: number;
	};

	type ActivityPlansRequest = {
		query: string;
		page: number;
		pageSize: number;
		sortField: SortField<ActivityPlan>;
	};

	const [activityPlansRequest, setActivityPlansRequest] = useState<ActivityPlansRequest>({
		query: "",
		page: 1,
		pageSize: 20,
		sortField: { property: "isFavorite", direction: "asc" },
	});
	const { api: activityPlansApi } = useActivityPlansDataAPI();

	const activityPlansFetchDataFn = async (request: ActivityPlansRequest, signal?: AbortSignal) =>
		await activityPlansApi.getActivityPlans(request, signal);

	const {
		data: activityPlansData,
		loading: loadingActivityPlans,
		triggerRender: activityPlansTriggerRender,
	} = useData(activityPlansFetchDataFn, activityPlansRequest);
	const [selectedActivityPlan, setSelectedActivityPlan] = useState<ActivityPlan>();

	return (
		<BaseLayoutNew
			id="content"
			pageHeader={
				<PageHeader
					title="Page title"
					utilities={{
						general: (
							<>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSearch />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconDownload />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSettings />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconOptions />
									</Icon>
								</Button>
							</>
						),
						productSpecific: (
							<>
								<Button variant="primary">Primary action</Button>
								<Button variant="secondary">Secondary action</Button>
							</>
						),
					}}
					pickers={{
						loading: loading,
						items: [
							sitesData && (
								<SitePicker
									items={sitesData.items}
									sort={sitesRequest.sortField}
									setSort={(property, direction) =>
										setSitesRequest((prev) => ({
											...prev,
											sortField: {
												property,
												direction:
													property === sitesRequest.sortField.property
														? invertDirection(sitesRequest.sortField.direction)
														: direction,
											},
										}))
									}
									search={{
										query: sitesRequest.query,
										onSearch: (query) => setSitesRequest((prev) => ({ ...prev, query })),
									}}
									onLoadMore={() =>
										setSitesRequest((prev) => ({ ...prev, pageSize: prev.pageSize + 20 }))
									}
									totalItems={sitesData.totalItems}
									selectedSite={selectedSite}
									onSelectedSite={setSelectedSite}
									onFavorite={async (site, isFavorite) => {
										await sitesApi.updateFavoriteSite(site, isFavorite);
										sitesTriggerRender();
									}}
									loading={loadingSites}
									editSitesUrl="https://my2.siteimprove.com/Settings/Sites/v2"
									extraColumns={[colPages(), colVisits()]}
								/>
							),
							<PeriodPicker
								key="period-picker"
								periodButtonVariant="borderless"
								periodButtonSize="large"
								periodButtonStyle={{ minHeight: "initial" }}
								value={periodPickerValue}
								onChange={setPeriodPickerValue}
							/>,
							activityPlansData && (
								<PageHeaderPicker
									search={{
										query: activityPlansRequest.query,
										onSearch: (query) => setActivityPlansRequest((prev) => ({ ...prev, query })),
									}}
									onLoadMore={() =>
										setActivityPlansRequest((prev) => ({ ...prev, pageSize: prev.pageSize + 20 }))
									}
									itemsCount={activityPlansData.items.length}
									totalItems={activityPlansData.totalItems}
									selectedItem={selectedActivityPlan}
									popoverButtonIcon={<IconGoalTarget />}
									selectedItemStringify={(item) => item.name}
									loading={loadingActivityPlans}
									texts={{
										buttonContentNoItemSelected: "Select an Activity Plan",
										searchPlaceholder: "Search for Activity Plans",
										showingXOfYItems: (showing, total) =>
											`Showing ${toFormattedNumberString({
												number: showing,
												locale,
											})} of ${toFormattedNumberString({ number: total, locale })} Activity Plans`,
									}}
									toolbarActions={
										<Button variant="primary" href="https://fancy.siteimprove.com/">
											Edit Activity Plans
										</Button>
									}
									contentItems={(
										firstFocusableRef,
										close,
										tableClassName,
										selectButtonClassName
									) => (
										<Table
											items={activityPlansData.items}
											columns={[
												{
													header: { content: "Favourite", property: "isFavorite" },
													render: (item) => (
														<Starred
															isStarred={item.isFavorite}
															onChange={async (starred) => {
																await activityPlansApi.updateFavoriteActivityPlan(item, starred);
																activityPlansTriggerRender();
															}}
															aria-label="Favorite item"
														/>
													),
												},
												{
													header: { content: "Activity Plan", property: "name" },
													render: (item, cellPosition) => (
														<Button
															variant="borderless"
															ref={cellPosition.rowNum === 0 ? firstFocusableRef : undefined}
															onClick={() => {
																setSelectedActivityPlan(item);
																close();
															}}
															className={selectButtonClassName}
														>
															{item.name}
														</Button>
													),
													options: { isKeyColumn: true, cellPadding: "none" },
												},
												{
													header: {
														content: "Search engine visibility",
														property: "searchEngineVisibility",
													},
													render: (item) => (
														<FormattedNumber number={item.searchEngineVisibility} format="number" />
													),
													options: { align: "right" },
												},
												{
													header: { content: "Keywords", property: "keywords" },
													render: (item) => (
														<FormattedNumber number={item.keywords} format="number" />
													),
													options: { align: "right" },
												},
												{
													header: { content: "Competitors", property: "competitors" },
													render: (item) => (
														<FormattedNumber number={item.competitors} format="number" />
													),
													options: { align: "right" },
												},
											]}
											sort={activityPlansRequest.sortField}
											setSort={(property, direction) =>
												setActivityPlansRequest((prev) => ({
													...prev,
													sortField: {
														property,
														direction:
															property === activityPlansRequest.sortField.property
																? invertDirection(activityPlansRequest.sortField.direction)
																: direction,
													},
												}))
											}
											loading={false}
											highlightRow={(item) => item.name === selectedActivityPlan?.name}
											className={tableClassName}
										/>
									)}
								/>
							),
						],
					}}
				/>
			}
		>
			<>Page content 🦔</>
		</BaseLayoutNew>
	);
};

const UsageHeaderWithAlert = () => {
	const locale = useFormattingLanguage();

	// Period Picker
	const [periodPickerValue, setPeriodPickerValue] = useState<PeriodPickerValue | null>(null);

	const { ColorWhite } = useDesignToken();

	return (
		<BaseLayoutNew
			id="content"
			topHeaderArea={
				<Message type="negative" style={{ borderRadius: "0" }}>
					<Paragraph>
						Some error message with a <Link href="https://fancylib.netlify.app">Link</Link>
					</Paragraph>
				</Message>
			}
			pageHeader={
				<PageHeader
					title="Page title"
					breadcrumbs={{
						"aria-label": "Breadcrumbs",
						items: [
							{ title: "Level 1", url: "https://fancylib.netlify.app" },
							{ title: "Level 2", url: null },
						],
					}}
					utilities={{
						general: (
							<>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSearch />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconDownload />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconSettings />
									</Icon>
								</Button>
								<Button variant="borderless" aria-label="Icon only button">
									<Icon>
										<IconOptions />
									</Icon>
								</Button>
							</>
						),
						productSpecific: (
							<>
								<Button variant="primary">Primary action</Button>
								<Button variant="secondary">Secondary action</Button>
							</>
						),
					}}
					pickers={{
						loading: false,
						items: [
							<SitePicker
								key="site-picker"
								items={[]}
								sort={{ property: "isFavorite", direction: "asc" }}
								setSort={() => {}}
								search={{ query: "", onSearch: () => {} }}
								onLoadMore={() => {}}
								totalItems={0}
								selectedSite={undefined}
								onSelectedSite={() => {}}
								onFavorite={() => {}}
								editSitesUrl="https://my2.siteimprove.com/Settings/Sites/v2"
							/>,
							<PageHeaderPicker
								key="group-picker"
								search={{ query: "", onSearch: () => {} }}
								onLoadMore={() => {}}
								itemsCount={0}
								totalItems={0}
								selectedItem={undefined}
								popoverButtonIcon={<IconGroup />}
								selectedItemStringify={() => "-"}
								texts={{
									buttonContentNoItemSelected: "Select a group",
									searchPlaceholder: "Search for groups",
									showingXOfYItems: (showing, total) =>
										`Showing ${toFormattedNumberString({
											number: showing,
											locale,
										})} of ${toFormattedNumberString({ number: total, locale })} groups`,
								}}
								toolbarActions={
									<Button variant="primary" href="https://fancy.siteimprove.com/">
										Edit groups
									</Button>
								}
								contentItems={() => (
									<EmptyState
										type="reassure"
										heading="No groups available"
										style={{ backgroundColor: ColorWhite }}
									/>
								)}
							/>,
							<PeriodPicker
								key="period-picker"
								periodButtonVariant="borderless"
								periodButtonSize="large"
								periodButtonStyle={{ minHeight: "initial" }}
								value={periodPickerValue}
								onChange={setPeriodPickerValue}
							/>,
							<PageHeaderPicker
								key="filter-picker"
								search={{ query: "", onSearch: () => {} }}
								onLoadMore={() => {}}
								itemsCount={0}
								totalItems={0}
								selectedItem={undefined}
								popoverButtonIcon={<IconFunnel />}
								selectedItemStringify={() => "-"}
								texts={{
									buttonContentNoItemSelected: "Select a filter",
									searchPlaceholder: "Search for filters",
									showingXOfYItems: (showing, total) =>
										`Showing ${toFormattedNumberString({
											number: showing,
											locale,
										})} of ${toFormattedNumberString({ number: total, locale })} filters`,
								}}
								toolbarActions={
									<Button variant="primary" href="https://fancy.siteimprove.com/">
										Edit filters
									</Button>
								}
								contentItems={() => (
									<EmptyState
										type="reassure"
										heading="No filters available"
										style={{ backgroundColor: ColorWhite }}
									/>
								)}
							/>,
						],
					}}
				/>
			}
		>
			<>Page content 🦔</>
		</BaseLayoutNew>
	);
};

const useActivityPlansDataAPI = () => {
	type ActivityPlanDto = {
		isFavorite: boolean;
		name: string;
		searchEngineVisibility: number;
		keywords: number;
		competitors: number;
	};

	type ActivityPlansRequest = {
		query: string;
		page: number;
		pageSize: number;
		sortField: SortField<ActivityPlanDto>;
	};

	const dataSourceRef = useRef({
		activityPlans: [
			{
				isFavorite: true,
				name: "Activity plan Monkey",
				searchEngineVisibility: 1200,
				keywords: 150,
				competitors: 10,
			},
			{
				isFavorite: true,
				name: "Activity plan Gorilla",
				searchEngineVisibility: 230,
				keywords: 200,
				competitors: 12,
			},
			{
				isFavorite: false,
				name: "Activity plan Superman",
				searchEngineVisibility: 430,
				keywords: 210,
				competitors: 7,
			},
			{
				isFavorite: false,
				name: "Activity plan Batman",
				searchEngineVisibility: 780,
				keywords: 34,
				competitors: 8,
			},
			{
				isFavorite: false,
				name: "Activity plan Wonder woman",
				searchEngineVisibility: 250,
				keywords: 5,
				competitors: 23,
			},
			{
				isFavorite: false,
				name: "Activity plan Robin",
				searchEngineVisibility: 126,
				keywords: 3,
				competitors: 16,
			},
			{
				isFavorite: false,
				name: "Activity plan Dog",
				searchEngineVisibility: 87,
				keywords: 36,
				competitors: 8,
			},
			{
				isFavorite: false,
				name: "Activity plan Cat",
				searchEngineVisibility: 11,
				keywords: 130,
				competitors: 9,
			},
		],
	});

	const getActivityPlans = async (request: ActivityPlansRequest, signal?: AbortSignal) => {
		if (signal?.aborted) {
			throw new DOMException("Aborted", "AbortError");
		}

		const items = dataSourceRef.current.activityPlans;

		return {
			items: (request.query
				? items.filter((item) => item.name.toLowerCase().includes(request.query.toLowerCase()))
				: items.slice((request.page - 1) * request.pageSize, request.pageSize)
			).sort((a, b) => {
				if (request.sortField.property === "isFavorite") {
					return request.sortField.direction === "asc"
						? a.isFavorite === b.isFavorite
							? 0
							: a.isFavorite
							? -1
							: 1
						: b.isFavorite === a.isFavorite
						? 0
						: b.isFavorite
						? -1
						: 1;
				} else if (request.sortField.property === "name") {
					return request.sortField.direction === "asc"
						? a.name.localeCompare(b.name)
						: b.name.localeCompare(a.name);
				} else if (request.sortField.property === "searchEngineVisibility") {
					return request.sortField.direction === "asc"
						? a.searchEngineVisibility - b.searchEngineVisibility
						: b.searchEngineVisibility - a.searchEngineVisibility;
				} else if (request.sortField.property === "keywords") {
					return request.sortField.direction === "asc"
						? a.keywords - b.keywords
						: b.keywords - a.keywords;
				} else if (request.sortField.property === "competitors") {
					return request.sortField.direction === "asc"
						? a.competitors - b.competitors
						: b.competitors - a.competitors;
				}

				return 0;
			}),
			totalItems: items.length,
		};
	};

	return {
		api: {
			getActivityPlans,
			updateFavoriteActivityPlan: async (activityPlan: ActivityPlanDto, isFavorite: boolean) => {
				dataSourceRef.current.activityPlans.find((x) => x.name === activityPlan.name)!.isFavorite =
					isFavorite;
			},
		},
	};
};

const useSitesDataAPI = () => {
	type SiteDto = Site & {
		pages: number | null;
		visits: number | null;
	};

	type SitesRequest = {
		query: string;
		pageNumber: number;
		pageSize: number;
		sortField: SortField<SiteDto>;
	};

	function getRandomIntInclusive(min: number, max: number) {
		const minCeiled = Math.ceil(min);
		const maxFloored = Math.floor(max);
		return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
	}

	const dataSourceRef = useRef<{ sites: SiteDto[] }>({
		sites: [
			{ name: "Siteimprove.au", url: "http://siteimprove.au" },
			{
				name: "Siteimprove marketing web site: Built to help teams optimize reach, reputation, revenue, and returns",
				url: "https://www.siteimprove.com/platform/search-engine-marketing/competitive-research",
			},
			{ name: "Siteimprove.com", url: "http://siteimprove.com" },
			{ name: "Siteimprove.uk", url: "http://siteimprove.uk" },
		].map((x, i) => ({
			...x,
			id: 3003 + i,
			pages: getRandomIntInclusive(100, 1000),
			visits: getRandomIntInclusive(100, 600),
			isFavorite: i < 2,
			imageSrc: IllustrationEducate,
		})),
	});

	const sortSitesFn = (request: SitesRequest) => (a: SiteDto, b: SiteDto) => {
		if (request.sortField.property === "isFavorite") {
			return request.sortField.direction === "asc"
				? a.isFavorite === b.isFavorite
					? 0
					: a.isFavorite
					? -1
					: 1
				: b.isFavorite === a.isFavorite
				? 0
				: b.isFavorite
				? -1
				: 1;
		} else if (request.sortField.property === "name") {
			return request.sortField.direction === "asc"
				? a.name.localeCompare(b.name)
				: b.name.localeCompare(a.name);
		} else if (request.sortField.property === "url") {
			return request.sortField.direction === "asc"
				? a.url.localeCompare(b.url)
				: b.url.localeCompare(a.url);
		} else if (request.sortField.property === "pages") {
			return request.sortField.direction === "asc" ? a.pages! - b.pages! : b.pages! - a.pages!;
		} else if (request.sortField.property === "visits") {
			return request.sortField.direction === "asc" ? a.visits! - b.visits! : b.visits! - a.visits!;
		}

		return 0;
	};

	const getSites = async (request: SitesRequest, signal?: AbortSignal) => {
		if (signal?.aborted) {
			throw new DOMException("Aborted", "AbortError");
		}

		const items = dataSourceRef.current.sites;

		return {
			items: (request.query
				? items.filter((item) => {
						const queryLowerCase = request.query.toLowerCase();
						return (
							item.name.toLowerCase().includes(queryLowerCase) ||
							item.url.toLowerCase().includes(queryLowerCase)
						);
				  })
				: items.slice((request.pageNumber - 1) * request.pageSize, request.pageSize)
			).sort(sortSitesFn(request)),
			totalItems: items.length,
		};
	};

	return {
		api: {
			getSites,
			updateFavoriteSite: async (site: Site, isFavorite: boolean) => {
				dataSourceRef.current.sites.find((x) => x.name === site.name)!.isFavorite = isFavorite;
			},
		},
	};
};
